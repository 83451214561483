<template>
    <v-list-item v-if="!this.$vuetify.display.mobile" lines="two" :title="userEmail">
        <div class="text-end text-subtitle-2">Logged in as {{ userRole }} </div>
    </v-list-item>

    <!-- Notifications Menu -->
    <v-menu v-model="notificationPanel" offset-y :close-on-content-click="false" max-height="464"
        transition="slide-y-transition">
        <template #activator="{ props }">
            <v-btn variant="text" density="compact" v-bind="props"
                :style="{ opacity: notificationsCount == 0 ? 0.6 : 1 }" stacked>
                <v-badge v-if="notificationsCount > 0" :content="notificationsCount" color="primary">
                    <v-icon>mdi-bell</v-icon>
                </v-badge>
                <v-icon v-else>mdi-bell</v-icon>
            </v-btn>
        </template>

        <v-card min-width="350" max-width="450" class="mt-1" color="navBar" rounded="lg">
            <v-list lines="two" bg-color="cards" @scroll.passive="handleScroll"
                style="overflow-y: auto; max-height: 400px;">
                <v-empty-state v-if="notifications.length == 0" title="No Notifications"
                    text="Notification Inbox Empty">

                    <template #media>
                        <v-img src="@/assets/noNotifications.png" width="auto" height="200px" />
                    </template>

                </v-empty-state>
                <div v-else>
                    <div v-for="(notification, index) in notifications" :key="index">
                        <!-- If notification is being deleted, show skeleton loader -->
                        <v-skeleton-loader v-if="isDeleting[notification.id]" type="list-item-avatar-two-line"
                            color="cards"></v-skeleton-loader>

                        <!-- If notification has a link and is not being deleted -->
                        <v-list-item v-else-if="notification.link" @click="goToLink(notification.link, notification.id)"
                            density="compact" :style="{ opacity: notification.clicked ? 0.4 : 1 }">
                            <template #prepend>
                                <v-avatar :color="this.getColor(notification.icon)">
                                    <v-icon>{{ notification.icon }}</v-icon>
                                </v-avatar>
                            </template>

                            <v-list-item-title>{{ notification.title }}</v-list-item-title>
                            <v-list-item-subtitle>{{ notification.body }}</v-list-item-subtitle>

                            <template #append>
                                <v-btn icon variant="text" @click.stop="clearNotification(notification.id)">
                                    <v-icon color="secondary" size="small">mdi-close</v-icon>
                                </v-btn>
                            </template>
                        </v-list-item>

                        <!-- If notification has no link and is not being deleted -->
                        <v-list-item v-else density="compact" :style="{ opacity: notification.clicked ? 0.4 : 1 }">
                            <template #prepend>
                                <v-avatar :color="this.getColor(notification.icon)">
                                    <v-icon>{{ notification.icon }}</v-icon>
                                </v-avatar>
                            </template>

                            <v-list-item-title>{{ notification.title }}</v-list-item-title>
                            <v-list-item-subtitle>{{ notification.text }}</v-list-item-subtitle>

                            <template #append>
                                <v-btn icon variant="text" @click.stop="clearNotification(notification.id)">
                                    <v-icon color="secondary" size="small">mdi-close</v-icon>
                                </v-btn>
                            </template>
                        </v-list-item>
                    </div>
                </div>
                <!-- Loading more indicator -->
                <div v-if="loadingMore" class="text-center my-2">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div>
            </v-list>
            <v-progress-linear :indeterminate="loadingMore"></v-progress-linear>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn size="small" :loading="isLoading" @click="clearAllNotifications">Clear Loaded</v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>

    <!-- User Menu -->
    <div class="mr-2">
        <v-menu v-model="menu" :close-on-content-click="false" location="bottom">
            <template #activator="{ props }">
                <v-btn icon v-bind="props">
                    <v-avatar :color="getColorByInitial(userName)" size="large">
                        <span class="text-h5">{{ getInitials(userName) }}</span>
                    </v-avatar>
                </v-btn>
            </template>

            <v-card min-width="300" color="cards">
                <div class="my-6 mx-auto text-center">
                    <v-avatar :color="getColorByInitial(userName)" size="large">
                        <span class="text-h5">{{ getInitials(userName) }}</span>
                    </v-avatar>
                    <h3>{{ userName }}</h3>
                </div>

                <v-divider></v-divider>

                <v-list bg-color="navBar">
                    <v-list-item>
                        <v-btn block color="primary" prepend-icon="mdi-account-box-outline"
                            @click="gotoPage('/myaccount')" variant="tonal">
                            My Account
                        </v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn block color="primary" prepend-icon="mdi-form-textbox-password"
                            @click="gotoPage('/changepassword')" variant="tonal">
                            Change Password
                        </v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn block color="primary" prepend-icon="mdi-theme-light-dark" @click="changeTheme"
                            variant="tonal">
                            Change Theme
                        </v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn block color="primary" @click="logout"> Logout </v-btn>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
import router from "@/router";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useTheme } from "vuetify";
import { onMessage } from "firebase/messaging";
import { onDisconnect, set, ref, getDatabase, serverTimestamp } from "firebase/database";
import { getCurrentUser, logout, firestore, messaging } from "@/firebase";
import { doc, getDocs, collection, deleteDoc, updateDoc, query, where, orderBy, startAfter, limit, writeBatch, onSnapshot } from "firebase/firestore";

export default {
    data() {
        return {
            userEmail: localStorage.email,
            userRole: localStorage.userRole,
            userName: localStorage.userName,
            userUID: localStorage.uid,
            menu: false,
            notificationPanel: false,
            notifications: [],
            notificationsCount: 0,
            isLoading: false,
            isDeleting: {},
            lastVisibleNotification: null,
            loadingMore: false,
            allNotificationsLoaded: false,
            intervalId: null,
            colorMap: {
                A: "red",
                B: "pink",
                C: "purple",
                D: "deep-purple",
                E: "indigo",
                F: "blue",
                G: "light-blue",
                H: "cyan",
                I: "teal",
                J: "green",
                K: "light-green",
                L: "lime",
                M: "yellow",
                N: "amber",
                O: "orange",
                P: "deep-orange",
                Q: "brown",
                R: "blue-grey",
                S: "grey",
                T: "red lighten-2",
                U: "pink lighten-2",
                V: "purple lighten-2",
                W: "deep-purple lighten-2",
                X: "indigo lighten-2",
                Y: "blue lighten-2",
                Z: "cyan lighten-2"
            },
        };
    },
    async created() {
        await getCurrentUser();
        this.userEmail = localStorage.email;
        this.userRole = localStorage.userRole;
        this.userName = localStorage.userName;
        this.userUID = localStorage.uid;

        onMessage(messaging, (payload) => {
            if (document.visibilityState === "visible") {
                new Notification(payload.data.title, {
                    body: payload.data.body,
                    icon: payload.data.icon,
                });
            }
        });

        const userRef = doc(firestore, "users", localStorage.uid);
        const notificationsRef = collection(userRef, "notifications");

        let initialLoad = true;

        // Listener for the first 7 notifications
        this.notificationsUnsubscribe = onSnapshot(
            query(notificationsRef, orderBy("timestamp", "desc"), limit(7)),
            (snapshot) => {
                const newNotifications = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                if (initialLoad) {
                    // Load initial notifications without showing toast
                    this.notifications = newNotifications;
                    initialLoad = false;
                } else {
                    // Show toast for new notifications
                    snapshot.docChanges().forEach((change) => {
                        if (change.type === "added") {
                            const notification = { id: change.doc.id, ...change.doc.data() };
                            this.notifications.unshift(notification);
                            toast.info(notification.title, {
                                theme: this.$vuetify.theme?.name === "lightTheme" ? "light" : "dark",
                                autoClose: 6000,
                            });
                        }
                    });
                }

                // Track the last visible document for pagination
                this.lastVisibleNotification = snapshot.docs[snapshot.docs.length - 1];
            }
        );

        // Listener for unseen notifications count
        this.unseenCountUnsubscribe = onSnapshot(
            query(notificationsRef, where("clicked", "==", false)),
            (snapshot) => {
                this.notificationsCount = snapshot.size; // Updates unseen notifications count
            }
        );

        // Set online status when connected
        const realtimeDB = getDatabase();
        const userRefRealtimeDB = ref(realtimeDB, `onlineUsers/${localStorage.uid}`);
        set(userRefRealtimeDB, { online: true, timestamp: serverTimestamp() });
        // Handle disconnect
        onDisconnect(userRefRealtimeDB).set({ online: false, timestamp: serverTimestamp() });
    },
    beforeUnmount() {
        // Unsubscribe from Firestore listeners
        if (this.notificationsUnsubscribe) this.notificationsUnsubscribe();
        if (this.unseenCountUnsubscribe) this.unseenCountUnsubscribe();
    },
    methods: {
        gotoPage(path) {
            this.menu = false;
            router.push(path);
        },
        async handleScroll(event) {
            const list = event.target;
            // Check if scrolled to bottom
            if (list.scrollHeight - list.scrollTop === list.clientHeight) {
                if (!this.loadingMore) {
                    await this.loadMoreNotifications(true); // Load more notifications
                }
            }
        },
        async loadMoreNotifications() {
            if (this.allNotificationsLoaded || !this.lastVisibleNotification) return;

            this.loadingMore = true;

            const userRef = doc(firestore, "users", this.userUID);
            const notificationsRef = collection(userRef, "notifications");

            const notificationsQuery = query(
                notificationsRef,
                orderBy("timestamp", "desc"),
                startAfter(this.lastVisibleNotification),
                limit(7)
            );

            const snapshot = await getDocs(notificationsQuery);

            if (snapshot.empty) {
                this.allNotificationsLoaded = true;
            } else {
                const newNotifications = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                this.notifications.push(...newNotifications);
                this.lastVisibleNotification = snapshot.docs[snapshot.docs.length - 1];
            }

            this.loadingMore = false;
        },
        async markNotificationAsClicked(notificationId) {
            const userRef = doc(firestore, "users", this.userUID);
            const notificationRef = doc(userRef, "notifications", notificationId);
            await updateDoc(notificationRef, { clicked: true });

            // Update the notification in the list
            const notificationIndex = this.notifications.findIndex(n => n.id === notificationId);
            if (notificationIndex !== -1) {
                this.notifications[notificationIndex].clicked = true;
            }
        },
        async goToLink(link, notificationId) {
            if (link) {
                this.notificationPanel = false;
                router.push(link);
            }
            await this.markNotificationAsClicked(notificationId);
        },
        async clearNotification(notificationId) {
            this.isDeleting[notificationId] = true;

            const userRef = doc(firestore, "users", this.userUID);
            await deleteDoc(doc(userRef, "notifications", notificationId));

            this.notifications = this.notifications.filter((n) => n.id !== notificationId);
            delete this.isDeleting[notificationId];
        },
        async clearAllNotifications() {
            this.isLoading = true;

            const userRef = doc(firestore, "users", this.userUID);
            const batch = writeBatch(firestore);

            this.notifications.forEach((notification) => {
                const notificationRef = doc(userRef, "notifications", notification.id);
                batch.delete(notificationRef);
            });

            try {
                await batch.commit();
                this.notifications = [];
                this.allNotificationsLoaded = false; // Reset for future loading
            } catch (error) {
                console.error("Error clearing notifications: ", error);
            } finally {
                this.isLoading = false;
            }
        },
        getColor(icon) {
            const colorMap = {
                "mdi-fast-forward": "green",
                "mdi-plus-circle": "green",
                "mdi-rewind": "red",
                "mdi-close-circle-outline": "red",
                "mdi-update": "orange",
                "mdi-palette": "purple",
                "mdi-database-marker": "blue",
                "mdi-check-all": "primary",
                "mdi-wallet": "teal",
                "mdi-spellcheck": "success",
                "mdi-delete": "error",
            };
            return colorMap[icon] || "grey";
        },
        changeTheme() {
            if (localStorage.darkmode == "true") {
                localStorage.darkmode = "false";
                this.setTheme(false);
            } else {
                localStorage.darkmode = "true";
                this.setTheme(true);
            }
        },
        logout: async function () {
            this.menu = false;
            await logout();
            this.setTheme(false);
            router.push("/login");
        },
        getInitials(name) {
            if (!name) return '';

            const nameParts = name.trim().split(' ');

            if (nameParts.length === 1) {
                return nameParts[0].charAt(0).toUpperCase();
            }

            const firstInitial = nameParts[0].charAt(0).toUpperCase();
            const secondInitial = nameParts[nameParts.length - 1].charAt(0).toUpperCase();

            return `${firstInitial}${secondInitial}`;
        },
        getColorByInitial(name) {
            if (!name) return 'grey'; // default color if no name is provided

            const initial = name.trim().charAt(0).toUpperCase();
            return this.colorMap[initial] || 'grey'; // default to grey if the initial is not found
        }
    },
    setup() {
        const theme = useTheme();
        return {
            theme,
            setTheme: (darkmode) => {
                theme.global.name.value = darkmode ? "darkTheme" : "lightTheme";
            },
        };
    },
};
</script>

<style></style>
